export default {
	global: {
		appVersion: '1.7.0',
		appDefaultEntryPoint: 'home',
		baseUrl: '/es.mmpp.MMPP',
		appDistribution: null
	},
	menu: {
		keepExpanded: true,
		searchBtn: false,
		notificationsBtn: false,
		helpBtn: false
	},
	form: {
		showSaveAndUpdate: true,
		showSaveAndNew: true
	}
};
